import * as React from "react";
import {
  Body,
  Container,
  Icon,
  Left,
  ListItem,
  Right,
  Tab,
  Tabs,
  Text,
  View,
} from "native-base";
import config from "../../utils/config";
import Axios from "axios";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList } from "react-native-gesture-handler";
import PreLoader from "../../components/PreLoader";
import HandleHTTPError from "../../utils/handleErrors";
import { Platform, StyleSheet, TextInput } from "react-native";
import { SearchBar } from "react-native-elements";
import { LoggContext } from "../../stores/Store";
import { loggedValues } from "../../utils/enums";

var numeral = require("numeral");

export default function RequestListScreen(props) {
  const { navigation } = props;
  const [working, setWorking] = React.useState(false);
  const [logged] = React.useContext(LoggContext);
  const [dataListChange, setDataListChange] = React.useState([]);
  const [dataListSub, setDataListSub] = React.useState([]);
  const [dataListCapex, setDataListCapex] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      getRequisitions();
    });
    return unsubscribe;
  }, [search]);

  const getRequisitions = (searchValue = search) => {
    setWorking(true);
    const uri = `${config.api.host}requisitions`;
    Axios.get(uri, {
      headers: config.api.headers,
      params: {
        search: searchValue,
      },
    })
      .then(({ data }) => {
        // setDataList(data.results);
        const currentYear = new Date().getFullYear();
        setDataListCapex(
          data.results.filter(
            (element) =>
              element.kind === "capex" && element.capex.year === currentYear
          )
        );
        setDataListChange(
          data.results.filter(
            (element) =>
              element.kind === "change" && element.capex.year === currentYear
          )
        );
        setDataListSub(
          data.results.filter(
            (element) => element.kind === "sub" && element.capex.year === currentYear
          )
        );
        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
        } else {
          HandleHTTPError(error, navigation);
        }
        setWorking(false);
      });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSubmit = () => {
    setSearch(searchTerm);
    getRequisitions(searchTerm);
  };

  const renderLastAprove = (element) => {
    if (element.approved_by) {
      return <Text>Aprobada por: {element.approved_by.full_name}</Text>;
    } else {
      if (element.rejected_by) {
        return (
          <>
            <Text>No aprobada por: {element.rejected_by.full_name}</Text>
            <Text>Razones: {element.observations}</Text>
          </>
        );
      }
    }
    return null;
  };

  const renderStatus = (status) => {
    if (status === 0) {
      return <Text style={{ color: "#007bff" }}>En proceso</Text>;
    }
    if (status === 1) {
      return <Text style={{ color: "#28a745" }}>Aprobada</Text>;
    }
    if (status === 2) {
      return <Text style={{ color: "#dc3545" }}>Rechazada</Text>;
    }
    if (status === 3) {
      return <Text style={{ color: "#dc3545" }}>Cancelada</Text>;
    }
  };

  const renderKind = (kind) => {
    if (kind === "capex") {
      return <Text style={{ color: "#007bff" }}>CAPEX</Text>;
    }
    if (kind === "sub") {
      return <Text style={{ color: "#6c757d" }}>Subdivisión</Text>;
    }
    if (kind === "change") {
      return <Text style={{ color: "#28a745" }}>Cambio</Text>;
    }
    return <Text style={{ color: "#dc3545" }}>AUTO</Text>;
  };

  const getNextScreen = (kind) => {
    if (logged === loggedValues.LOGGED) {
      return "RequestDetailScreen";
    }
    if (logged === loggedValues.COMPRAS) {
      return "RequestDetailScreen";
    }
    if (kind === "capex") {
      return "AproveCapexScreen";
    }
    if (kind === "sub") {
      return "AproveSubScreen";
    }
    if (kind === "change") {
      return "AproveCambioScreen";
    }
  };

  const renderNames = (element) => {
    if (element.kind === "change") {
      return (
        <>
          <Text>
            Emisor: {element.name} {renderKind(element.kind)}
          </Text>
          <Text>Receptor: {element.capex_receiver.name}</Text>
        </>
      );
    } else {
      return (
        <Text>
          {element.name} {renderKind(element.kind)}
        </Text>
      );
    }
  };

  const renderListItem = (element) => {
    const route = getNextScreen(element.kind);
    if (Platform.OS === "web") {
      return (
        <ListItem
          button
          onPress={() =>
            navigation.navigate(route, {
              item: element,
            })
          }
        >
          <Body>
            {/* <Text>
              {element.name} {renderKind(element.kind)}
            </Text> */}
            {renderNames(element)}
            <Text>Solicitante: {element.requested_by?.full_name}</Text>
            <Text>Descripción: {element.description}</Text>
            {/* <Text>Objetivo: {element.objective}</Text> */}
          </Body>
          <View>
            <Text>
              Monto solicitado:
              <Text style={{ fontWeight: "bold", color: "green" }}>
                {numeral(element.budget_requested).format("000,000.00")}
              </Text>
            </Text>
            {renderStatus(element.status)}
            {renderLastAprove(element)}
            {element.reviewer && (
              <Text>Aprobador pendiente: {element.reviewer?.full_name}</Text>
            )}
          </View>
        </ListItem>
      );
    }

    return (
      <ListItem
        button
        onPress={() => {
          navigation.navigate(route, {
            item: element,
          });
        }}
      >
        <Body>
          <Text>
            {element.name} {renderKind(element.kind)}
          </Text>
          <Text>Solicitante: {element.requested_by?.full_name}</Text>
          <Text>
            Monto solicitado:
            <Text style={{ fontWeight: "bold", color: "green" }}>
              {numeral(element.budget_requested).format("000,000.00")}
            </Text>
          </Text>
          <Text>Descripción: {element.description}</Text>
          {renderStatus(element.status, element)}
          {renderLastAprove(element)}
          <Text>Aprobador pendiente: {element.reviewer?.full_name}</Text>
        </Body>
      </ListItem>
    );
  };

  if (working) {
    return <PreLoader />;
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <SearchBar
          platform={Platform.OS}
          lightTheme
          placeholder="Buscar..."
          onChangeText={(searchString) => {
            handleSearch(searchString);
          }}
          value={searchTerm}
          onSubmitEditing={handleSubmit}
        />
        <Tabs>
          <Tab
            activeTabStyle={{ backgroundColor: "#fff" }}
            activeTextStyle={{ color: "#000" }}
            tabStyle={{ backgroundColor: "#fff" }}
            heading="CAPEX"
          >
            <FlatList
              data={dataListCapex}
              renderItem={({ item }) => renderListItem(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>
          <Tab
            activeTabStyle={{ backgroundColor: "#fff" }}
            activeTextStyle={{ color: "#000" }}
            tabStyle={{ backgroundColor: "#fff" }}
            heading="Cambio"
          >
            <FlatList
              data={dataListChange}
              renderItem={({ item }) => renderListItem(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>
          <Tab
            activeTabStyle={{ backgroundColor: "#fff" }}
            activeTextStyle={{ color: "#000" }}
            tabStyle={{ backgroundColor: "#fff" }}
            heading="Subdivisión"
          >
            <FlatList
              data={dataListSub}
              renderItem={({ item }) => renderListItem(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>
        </Tabs>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
  searchSection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: "#fff",
    color: "#424242",
    borderWidth: 1,
    borderColor: "#eee",
  },
});
