import * as React from "react";
import {
  Body,
  Container,
  Icon,
  Left,
  ListItem,
  Right,
  Tab,
  TabHeading,
  Tabs,
  Text,
  View,
} from "native-base";
import config from "../../utils/config";
import Axios from "axios";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList } from "react-native-gesture-handler";
import PreLoader from "../../components/PreLoader";
import HandleHTTPError from "../../utils/handleErrors";
import { Platform, StyleSheet, TextInput } from "react-native";
import { SearchBar } from "react-native-elements";
import Svg, { Circle, Text as SVGText } from "react-native-svg";

var numeral = require("numeral");
import moment from "moment";
import AsyncStorage from '@react-native-async-storage/async-storage'
import { UserContext } from "../../stores/Store";

export default function HomeScreenAprovations(props) {
  const { navigation } = props;
  const [working, setWorking] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [dataListChange, setDataListChange] = React.useState([]);
  const [dataListSub, setDataListSub] = React.useState([]);
  const [dataListCapex, setDataListCapex] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [user] = React.useContext(UserContext);

  const [tab, setTab] = React.useState(
    parseInt(AsyncStorage.getItem("tabAprovation")) || 0
  );

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      setWorking(true);
      const uri = `${config.api.host}requisitions`;
      Axios.get(uri, {
        headers: config.api.headers,
        params: {
          search,
        },
      })
        .then(({ data }) => {
          const capex = data.results.filter(
            (element) => element.kind === "capex"
          );
          const change = data.results.filter(
            (element) => element.kind === "change"
          );
          const sub = data.results.filter((element) => element.kind === "sub");
          setDataListCapex(
            capex.filter((element) => element.reviewer?.user === user.user_id)
          );
          setDataListChange(
            change.filter((element) => element.reviewer?.user === user.user_id)
          );
          setDataListSub(
            sub.filter((element) => element.reviewer?.user === user.user_id)
          );
          setWorking(false);
          getAutoRequisitions();
        })
        .catch((error) => {
          if (Platform.OS === "web") {
            alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
          } else {
            HandleHTTPError(error, navigation);
          }
          setWorking(false);
        });

      
    });
    return unsubscribe;
  }, [search, navigation]);

  const getAutoRequisitions = () => {
    setWorking(true);
    const uri = `${config.api.host}requisitions/auto`;
    Axios.get(uri, {
      headers: config.api.headers,
      params: {
        search,
      },
    })
      .then(({ data }) => {
        setDataList(
          data.results.filter(
            (element) => element.reviewer?.user === user.user_id
          )
        );
        setWorking(false);
      })
      .catch((error) => {
        if (Platform.OS === "web") {
          alert(`Ha ocurrido un error ${JSON.stringify(error.response)}`);
        } else {
          HandleHTTPError(error, navigation);
        }

        setWorking(false);
      });
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSubmit = () => {
    setSearch(searchTerm);
  };

  const renderStatus = (status) => {
    if (status === 0) {
      return <Text style={{ color: "#007bff" }}>En proceso</Text>;
    }
    if (status === 1) {
      return <Text style={{ color: "#28a745" }}>Aprobada</Text>;
    }
    if (status === 2) {
      return <Text style={{ color: "#dc3545" }}>Rechazada</Text>;
    }
    if (status === 3) {
      return <Text style={{ color: "#dc3545" }}>Cancelada</Text>;
    }
  };

  const renderKind = (kind) => {
    if (kind === "capex") {
      return <Text style={{ color: "#007bff" }}>CAPEX</Text>;
    }
    if (kind === "sub") {
      return <Text style={{ color: "#6c757d" }}>Subdivisión</Text>;
    }
    if (kind === "change") {
      return <Text style={{ color: "#28a745" }}>Cambio</Text>;
    }
    return <Text style={{ color: "#dc3545" }}>AUTO</Text>;
  };

  const getNextScreen = (kind) => {
    if (kind === "capex") {
      return "AproveCapexScreen";
    }
    if (kind === "sub") {
      return "AproveSubScreen";
    }
    if (kind === "change") {
      return "AproveCambioScreen";
    }
    return "AproveAutoScreen";
  };

  const renderListItem = (element) => {
    const route = getNextScreen(element.kind);
    if (Platform.OS === "web") {
      return (
        <ListItem
          button
          onPress={() =>
            navigation.navigate(route, {
              item: element.id,
            })
          }
        >
          <Body>
            <Text>
              {element.name} {renderKind(element.kind)}{" "}
              {new Date(element.created_at).toLocaleString()}
            </Text>
            <Text>Solicitante: {element.requested_by?.full_name}</Text>
            <Text>Descripción: {element.description}</Text>
            <Text>Objetivo: {element.objective}</Text>
          </Body>
          <View>
            <Text>
              Monto solicitado:
              <Text style={{ fontWeight: "bold", color: "green" }}>
                {numeral(element.budget_requested).format("000,000.00")}
              </Text>
            </Text>
            {renderStatus(element.status)}
          </View>
          <Right>
            <Icon name="md-arrow-forward" />
          </Right>
        </ListItem>
      );
    }
    return (
      <ListItem
        button
        onPress={() => {
          if (element.reviewer !== null) {
            navigation.navigate(route, {
              item: element.id,
            });
          }
        }}
      >
        <Body>
          <Text>
            {element.name} {renderKind(element.kind)}{" "}
            {new Date(element.created_at).toLocaleString()}
          </Text>
          <Text>Solicitante: {element.requested_by?.full_name}</Text>
          <Text>
            Monto solicitado:
            <Text style={{ fontWeight: "bold", color: "green" }}>
              {numeral(element.budget_requested).format("000,000.00")}
            </Text>
          </Text>
          <Text>Descripción: {element.description}</Text>
          <Text>Objetivo: {element.objective}</Text>
          {renderStatus(element.status)}
        </Body>
        <Right>
          <Icon name="md-arrow-forward" />
        </Right>
      </ListItem>
    );
  };

  const renderListItemAUTO = (element) => {
    const route = getNextScreen(element.kind);
    if (Platform.OS === "web") {
      return (
        <ListItem
          button
          onPress={() => {
            if (element.reviewer !== null) {
              navigation.navigate(route, {
                item: element.id,
              });
            }
          }}
        >
          <Body>
            <Text>
              {element.name} {renderKind(element.kind)}{" "}
              {new Date(element.created_at).toLocaleString()}
            </Text>
            <Text>Solicitante: {element.requested_by?.full_name}</Text>
            <Text>Descripción: {element.description}</Text>
            <Text>Objetivo: {element.objective}</Text>
          </Body>
          <View>
            <Text>
              Monto solicitado:
              <Text style={{ fontWeight: "bold", color: "green" }}>
                {numeral(element.qty_requested).format("000,000.00")}
              </Text>
            </Text>
            {renderStatus(element.status)}
          </View>
          <Right>
            <Icon name="md-arrow-forward" />
          </Right>
        </ListItem>
      );
    }
    return (
      <ListItem
        button
        onPress={() =>
          navigation.navigate(route, {
            item: element.id,
          })
        }
      >
        <Body>
          <Text>
            {element.name} {renderKind(element.kind)}{" "}
            {new Date(element.created_at).toLocaleString()}
          </Text>
          <Text>Solicitante: {element.requested_by?.full_name}</Text>
          <Text>
            Monto solicitado:
            <Text style={{ fontWeight: "bold", color: "green" }}>
              {numeral(element.qty_requested).format("000,000.00")}
            </Text>
          </Text>
          <Text>Descripción: {element.description}</Text>
          <Text>Objetivo: {element.objective}</Text>
          {renderStatus(element.status)}
        </Body>
        <Right>
          <Icon name="md-arrow-forward" />
        </Right>
      </ListItem>
    );
  };

  if (working) {
    return <PreLoader />;
  }
  if(Platform.OS === 'web'){
    return (
      <Container style={styles.container}>
        <SafeAreaView style={styles.content}>
          <SearchBar
            platform={Platform.OS}
            lightTheme
            placeholder="Buscar..."
            onChangeText={(searchString) => {
              handleSearch(searchString);
            }}
            value={searchTerm}
            onSubmitEditing={handleSubmit}
          />
          <Tabs
            initialPage={tab}
            onChangeTab={(item) => {
              setTab(item.i);
              AsyncStorage.setItem("tabAprovation", `${item.i}`);
            }}
          >
            <Tab
              heading={
                <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                  <Text numberOfLines={1} ellipsizeMode="tail">CAPEX</Text>
                  <Svg height="25" width="25">
                    <Circle
                      key="circ1"
                      cx="15"
                      cy="15"
                      r="10"
                      fill="white"
                      stroke="#999999"
                    />
                    <SVGText
                      key="text"
                      fill="#666666"
                      fontSize="15"
                      fontWeight="bold"
                      x="15"
                      y="20"
                      textAnchor="middle"
                    >
                      {dataListCapex.length}
                    </SVGText>
                  </Svg>
                </TabHeading>
              }
            >
              <FlatList
                data={dataListCapex}
                renderItem={({ item }) => renderListItem(item)}
                ListEmptyComponent={
                  <ListItem>
                    <Body>
                      <Text>Sin elementos para mostrar</Text>
                    </Body>
                  </ListItem>
                }
                keyExtractor={(item) => item.id}
              />
            </Tab>
            <Tab
              heading={
                <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                  <Text numberOfLines={1} ellipsizeMode="tail">Cambio</Text>
                  <Svg height="25" width="25">
                    <Circle
                      key="circ1"
                      cx="15"
                      cy="15"
                      r="10"
                      fill="white"
                      stroke="#999999"
                    />
                    <SVGText
                      key="text"
                      fill="#666666"
                      fontSize="15"
                      fontWeight="bold"
                      x="15"
                      y="20"
                      textAnchor="middle"
                    >
                      {dataListChange.length}
                    </SVGText>
                  </Svg>
                </TabHeading>
              }
            >
              <FlatList
                data={dataListChange}
                renderItem={({ item }) => renderListItem(item)}
                ListEmptyComponent={
                  <ListItem>
                    <Body>
                      <Text>Sin elementos para mostrar</Text>
                    </Body>
                  </ListItem>
                }
                keyExtractor={(item) => item.id}
              />
            </Tab>
            <Tab
              heading={
                <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                  <Text numberOfLines={1} ellipsizeMode="tail">Subdivisión</Text>
                  <Svg height="25" width="25">
                    <Circle
                      key="circ1"
                      cx="15"
                      cy="15"
                      r="10"
                      fill="white"
                      stroke="#999999"
                    />
                    <SVGText
                      key="text"
                      fill="#666666"
                      fontSize="15"
                      fontWeight="bold"
                      x="15"
                      y="20"
                      textAnchor="middle"
                    >
                      {dataListSub.length}
                    </SVGText>
                  </Svg>
                </TabHeading>
              }
            >
              <FlatList
                data={dataListSub}
                renderItem={({ item }) => renderListItem(item)}
                ListEmptyComponent={
                  <ListItem>
                    <Body>
                      <Text>Sin elementos para mostrar</Text>
                    </Body>
                  </ListItem>
                }
                keyExtractor={(item) => item.id}
              />
            </Tab>
  
            <Tab
              heading={
                <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                  <Text numberOfLines={1} ellipsizeMode="tail">AUTO</Text>
                  <Svg height="25" width="25">
                    <Circle
                      key="circ1"
                      cx="15"
                      cy="15"
                      r="10"
                      fill="white"
                      stroke="#999999"
                    />
                    <SVGText
                      key="text"
                      fill="#666666"
                      fontSize="15"
                      fontWeight="bold"
                      x="15"
                      y="20"
                      textAnchor="middle"
                    >
                      {dataList.length}
                    </SVGText>
                  </Svg>
                </TabHeading>
              }
            >
              <FlatList
                data={dataList}
                renderItem={({ item }) => renderListItemAUTO(item)}
                ListEmptyComponent={
                  <ListItem>
                    <Body>
                      <Text>Sin elementos para mostrar</Text>
                    </Body>
                  </ListItem>
                }
                keyExtractor={(item) => item.id}
              />
            </Tab>
          </Tabs>
        </SafeAreaView>
      </Container>
    );
  }

  return (
    <Container style={styles.container}>
      <SafeAreaView style={styles.content}>
        <SearchBar
          platform={Platform.OS}
          lightTheme
          placeholder="Buscar..."
          onChangeText={(searchString) => {
            handleSearch(searchString);
          }}
          value={searchTerm}
          onSubmitEditing={handleSubmit}
        />
        <Tabs
          initialPage={tab}
          onChangeTab={(item) => {
            setTab(item.i);
            AsyncStorage.setItem("tabAprovation", `${item.i}`);
          }}
        >
          <Tab
            heading={
              <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                <Text numberOfLines={1} ellipsizeMode="tail" style={{ fontSize: 10}}>CAPEX</Text>
                <Svg height="25" width="25">
                  <Circle
                    key="circ1"
                    cx="15"
                    cy="15"
                    r="10"
                    fill="white"
                    stroke="#999999"
                  />
                  <SVGText
                    key="text"
                    fill="#666666"
                    fontSize="15"
                    fontWeight="bold"
                    x="15"
                    y="20"
                    textAnchor="middle"
                  >
                    {dataListCapex.length}
                  </SVGText>
                </Svg>
              </TabHeading>
            }
          >
            <FlatList
              data={dataListCapex}
              renderItem={({ item }) => renderListItem(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>
          <Tab
            heading={
              <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                <Text numberOfLines={1} ellipsizeMode="tail" style={{ fontSize: 10}}>Cambio</Text>
                <Svg height="25" width="25">
                  <Circle
                    key="circ1"
                    cx="15"
                    cy="15"
                    r="10"
                    fill="white"
                    stroke="#999999"
                  />
                  <SVGText
                    key="text"
                    fill="#666666"
                    fontSize="15"
                    fontWeight="bold"
                    x="15"
                    y="20"
                    textAnchor="middle"
                  >
                    {dataListChange.length}
                  </SVGText>
                </Svg>
              </TabHeading>
            }
          >
            <FlatList
              data={dataListChange}
              renderItem={({ item }) => renderListItem(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>
          <Tab
            heading={
              <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                <Text numberOfLines={1} ellipsizeMode="tail" style={{ fontSize: 10}}>Subdivisión</Text>
                <Svg height="25" width="25">
                  <Circle
                    key="circ1"
                    cx="15"
                    cy="15"
                    r="10"
                    fill="white"
                    stroke="#999999"
                  />
                  <SVGText
                    key="text"
                    fill="#666666"
                    fontSize="15"
                    fontWeight="bold"
                    x="15"
                    y="20"
                    textAnchor="middle"
                  >
                    {dataListSub.length}
                  </SVGText>
                </Svg>
              </TabHeading>
            }
          >
            <FlatList
              data={dataListSub}
              renderItem={({ item }) => renderListItem(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>

          <Tab
            heading={
              <TabHeading style={{ backgroundColor: "#0B58A8" }}>
                <Text numberOfLines={1} ellipsizeMode="tail" style={{ fontSize: 10}}>AUTO</Text>
                <Svg height="25" width="25">
                  <Circle
                    key="circ1"
                    cx="15"
                    cy="15"
                    r="10"
                    fill="white"
                    stroke="#999999"
                  />
                  <SVGText
                    key="text"
                    fill="#666666"
                    fontSize="15"
                    fontWeight="bold"
                    x="15"
                    y="20"
                    textAnchor="middle"
                  >
                    {dataList.length}
                  </SVGText>
                </Svg>
              </TabHeading>
            }
          >
            <FlatList
              data={dataList}
              renderItem={({ item }) => renderListItemAUTO(item)}
              ListEmptyComponent={
                <ListItem>
                  <Body>
                    <Text>Sin elementos para mostrar</Text>
                  </Body>
                </ListItem>
              }
              keyExtractor={(item) => item.id}
            />
          </Tab>
        </Tabs>
      </SafeAreaView>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    padding: 10,
    ...(Platform.OS === "web" && {
      // maxWidth: '70%',
      width: "50%",
      alignSelf: "center",
    }),
  },
  searchSection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    flex: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    backgroundColor: "#fff",
    color: "#424242",
    borderWidth: 1,
    borderColor: "#eee",
  },
});
